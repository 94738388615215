import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Layout from "../../components/Layout";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function CommissionSalarySlip({ data }) {
  const { state } = useLocation();
  const receiptRef = useRef();
  const currentDate = new Date();
  const currentMonthName = monthNames[currentDate.getMonth()];
  const [commissionAmounts, setCommissionAmounts] = useState([]);
  const { profile } = useSelector((state) => state.profile);

  const [values, setValues] = useState(profile);

  const [invoiceNo, setInvoiceNo] = useState("");

  // Generate invoice number
  useEffect(() => {
    const generateInvoiceNo = () => {
      const datePart = moment().format("YYYYMMDD"); // Format date as YYYYMMDD
      const randomPart = Math.floor(1000 + Math.random() * 9000); // Random 4-digit number
      return `INV-${datePart}-${randomPart}`;
    };

    setInvoiceNo(generateInvoiceNo());
  }, []);

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    documentTitle: "Commission Slip",
  });

  // Handle percentage change and calculate amount
  const handlePayoutChange = (index, payoutPercentage) => {
    console.log(index, payoutPercentage)
    const updatedAmounts = [...commissionAmounts];
    const loan = state.unpaidLoans[index];
    const loanAmount = loan?.sanction || 0; // Get loan amount
    const commission = (loanAmount * payoutPercentage) / 100; // Calculate amount
    console.log((loanAmount * payoutPercentage) / 100)

    updatedAmounts[index] = {
      loanId: loan?._id, // Assuming each loan has a unique _id
      customerName: loan?.name,
      loanAmount,
      payoutPercentage,
      commissionAmount: commission,
    };

    setCommissionAmounts(updatedAmounts);
  };

  const MarkAsGenerated = async () => {
    const netPayableSalary = commissionAmounts.reduce(
      (sum, item) => sum + (item?.commissionAmount || 0),
      0
    );

    const employee = {
      employeeId: state._id,
      salary_year: new Date().getFullYear(),
      salary_month: currentMonthName,
      net_payable_salary: netPayableSalary,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}admin/markLoanForGeneratedSalary`,
        {
          loans: state.unpaidLoans,
          employee,
          commissionDetails: commissionAmounts, // Include the commission details
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
            version: "1.0.0",
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to generate salary. Please try again.");
    }
  };
  return (
    <Layout>
      <div className="content-i ">
        <div className="content-box ">
          <div className="element-wrapper">
            <div className="element-box">
              <div>
                <div
                  className="slip-container print-content"
                  ref={receiptRef}
                  style={{
                    padding: "20px",
                    border: "1px solid black",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>
                    {values?.companyName || "ROYAL FINANCIAL SERVICES"}
                  </h3>
                  <p style={{ textAlign: "center" }}>
                    {values?.address ||
                      "KUNJ PLAZA, 2nd FLOOR, JNASHI ROAD, DABRA, DIST-GWL"}
                  </p>

                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginTop: "20px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "200px" }}>Invoice No :</td>
                        <td>
                          <b>{invoiceNo || "________"}</b>
                        </td>
                        <td>Date:</td>
                        <td>
                          <b>
                            {moment(currentDate).format("DD-MMM-YYYY") ||
                              "________"}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" }}>Business State:</td>
                        <td>
                          <b>{values?.state || "________"}</b>
                        </td>
                      </tr>
                      <tr style={{ width: "200px" }}>
                        <td>Description of Services:</td>
                        <td colSpan="3" contentEditable>
                          <b contentEditable></b>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginTop: "20px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "200px" }}>Field Officer Name :</td>
                        <td>
                          <b>{state?.name || "________"}</b>
                        </td>
                        <td className="text-end">Mobile Number:</td>
                        <td className="text-center">
                          <b>{state?.mobile_no || "________"}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                    Customer Details
                  </h4>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th style={{ border: "1px solid black" }}>
                          Customer Name
                        </th>
                        <th style={{ border: "1px solid black" }}>
                          Loan/Disbursement Amount
                        </th>
                        <th style={{ border: "1px solid black" }}>
                          Payout % Inclusive
                        </th>
                        <th style={{ border: "1px solid black" }}>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state?.unpaidLoans.map((item, index) => (
                        <tr key={index}>
                          <td
                            style={{ border: "1px solid black" }}
                            className="text-uppercase"
                          >
                            {item?.name || "________"}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {item?.sanction || "________"} Rs/-
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            <input
                              type="number"
                              style={{
                                border: "none",
                                width: "70px",
                              }}
                              onChange={(e) =>
                                handlePayoutChange(index, e.target.value)
                              }
                            />
                            %
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {commissionAmounts[
                              index
                            ]?.commissionAmount?.toFixed(2) || "0.00"}{" "}
                            Rs/-
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>Bank Name:</td>
                        <td>
                          <b>{state?.bank_name || "________"}</b>
                        </td>
                        <td>Grand Total:</td>
                        <td>
                          <b>
                            {commissionAmounts
                              .reduce(
                                (sum, item) =>
                                  sum + (item?.commissionAmount || 0),
                                0
                              )
                              .toFixed(2)}{" "}
                            Rs/-
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td>Payee Name:</td>
                        <td>
                          <b>{state?.account_holder_name || "________"}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Bank Account:</td>
                        <td>
                          <b>{state?.account_number || "________"}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>IFSC Code:</td>
                        <td>
                          <b>{state?.ifsc_code || "________"}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <p style={{ marginTop: "30px" }}>
                    Seal & Signature Branch Manager
                  </p>
                </div>
                <p className="mt-4 fw-bold text-capitalize text-danger">
                  Please Note this that if you are generation salary for the
                  field officer then
                  <span className="text-success">
                    {" "}
                    clicking on mark as generated is mandatory
                  </span>{" "}
                  In case if you do not click on that then the change will not
                  reflect in the records
                </p>
                <div className="mt-4 d-flex justify-content-center align-items-center">
                  <div>
                    <button
                      style={{ marginTop: "20px" }}
                      className="btn btn-success"
                      onClick={() => MarkAsGenerated()}
                    >
                      Mark As Generated
                    </button>
                    {"   "}

                    <button
                      className="btn btn-primary"
                      onClick={handlePrint}
                      style={{ marginTop: "20px" }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
          @media print {
            .print-content {
              background-color: white;
              padding: 10px;
              height: 100vh;
            }
          }
        `}
      </style>
    </Layout>
  );
}

export default CommissionSalarySlip;
